import React from 'react'
import { forwardRef } from 'react'
import { FontSizeTokens, SizableText, SizableTextProps, useTheme } from 'tamagui'

import { getTextColorVar, TextColor, VideoTextSize } from '../utils/text'

export type VideoTextProps = {
  size?: VideoTextSize
  color?: TextColor | 'cta'
  isBold?: boolean
} & Omit<
  SizableTextProps,
  'fontFamily' | 'fontSize' | 'weight' | 'letterSpacing' | 'size' | 'color'
>

const VideoText = forwardRef<HTMLElement, VideoTextProps>(
  ({ size = 'md', color = 'primary', isBold, ...props }, ref) => {
    const sizeToken = ('$' + size) as FontSizeTokens
    const theme = useTheme()
    return (
      <SizableText
        {...props}
        textTransform={props.textTransform ?? 'uppercase'} // can be overwritten
        ref={ref}
        fontFamily={isBold ? '$specialBold' : '$special'}
        color={color !== 'cta' ? getTextColorVar(color) : undefined}
        size={sizeToken}
        letterSpacing={sizeToken}
        style={
          color === 'cta'
            ? {
                background: `linear-gradient(${theme.red?.get()}, ${theme.amber?.get()})`,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            : null
        }
      />
    )
  }
)

VideoText.displayName = 'VideoText'

export default VideoText
